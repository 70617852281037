@import '../../styles/colors.scss';

.error__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $LightBlue;
    font-size: larger;
    font-weight: bold;
    gap: 10px;
    width: 100%;
}