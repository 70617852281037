@import '../../styles/colors.scss';

.right_menu_projects {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;  
    border-right: 1px solid $LightBlue;
    padding-right: 10px;
    padding-top: 10px;
}

.button_projects {
    background-color: $white-color;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    padding: 5px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    
    &_active {
        background-color: $cardHoverBackground;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        padding: 5px;
        font-size: 20px;
        cursor: pointer;
        transition: background-color 0.3s;
        position: relative;
    }

    &:hover {
        background-color: $LightBlue;
        color: white;
    }

    &:active {
        background-color: $TableHeaderBlue;
    }

    &__alert {
        background-color: red;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 4px;
        right: 2px;
    }

    &__selectedIcon{
        background-color: orange;
        position: absolute;
        left: 0px;
        width: 10px;
        height: 15px;
    }
}