@import '../../styles/colors.scss';

.Upload_files_container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &_header {
        align-items: center;
        display: flex;
        font-size: 25px;
        color: $TableHeaderBlue;
        font-weight: bold;
        gap: 10px;
        padding: 15px 20px;
        text-align: center;
    }

    &_body {
        display: grid;
        grid-template-columns: 300px 1fr;
        padding: 10px;
        gap: 20px;
        height: calc(100vh - 150px);
        overflow: scroll;
    }

    &_save_button{
        display: flex;
        justify-content: flex-end;
        padding: 5px;
    }

    &_modal_user_container {

        background-color: rgba($color: #000000, $alpha: 0.2);
        position: absolute;
        gap: 10px;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;

        &_form_container{
            background-color: white;
            border-radius: 5px;
            height: 50vh;
            left: 50%;
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 20px;
            transform: translate(-50%, -50%);
            top: 50%;
            width: 50vw;
            z-index: 11;
        }

        &_header{
            display: flex;
            justify-content: flex-end;
        }

        &_input{
            background-color: #FAFAFA;
            box-shadow: 0px 0px 5px 0px rgba($color: #000000, $alpha: 0.3);
            border-radius: 5px;
            border: none;
            outline: none;
            padding: 10px;
            width: 100%;
        }
    }

    &_instructions_section {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba($color: #000000, $alpha: 0.7);
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;

        &_title {
            font-size: 16px;
            color: $TableHeaderBlue;
            font-weight: bold;
        }

        &_instructions_list {
            font-size: 14px;
            list-style-type: disc;
            padding: 20px;
            text-align: justify;
            margin-bottom: 10px;

            &_download {
                color: blue($color: #000000);
                font-weight: bold;
                margin-bottom: 10px;
                text-decoration: underline;
            }

            &_item {
                margin-bottom: 10px;
            }
        }


        &_detail {
            align-items: center;
            background-color: #CDCDCF;
            border-radius: 5px;
            display: grid;
            font-size: 12px;
            grid-template-columns: 20px 1fr;
            gap: 10px;
            padding: 10px;
            text-align: justify;
        }
    }

    &_form_section {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba($color: #000000, $alpha: 0.7);
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
    }

    &_form_title {
        font-size: 16px;
        color: $TableHeaderBlue;
        font-weight: bold;
    }

    &_custom_input {
        display: flex;
        justify-content: space-between;
        padding: 5px;
    }

    &_member_list_container {
        border-radius: 15px;
        border: 1px solid $TableHeaderBlue;
        padding: 10px;
        height: 250px;
        overflow: scroll;

        &::-webkit-scrollbar {
            width: 12px;
            /* Width of the scrollbar */
            background-color: transparent;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            /* Background of the scrollbar track */
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888;
            /* Color of the scrollbar thumb */
            border-radius: 10px;
            /* Rounded corners for the scrollbar thumb */
            background-clip: content-box;
            /* Clip the background to the content box */
        }

        &_item_user {
            align-items: center;
            background-color: #FAFBFD;
            box-shadow: 0px 0px 5px 0px rgba($color: #000000, $alpha: 0.7);
            border-radius: 10px;
            cursor: pointer;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 90px;
            gap: 10px;
            padding: 10px;
            margin-bottom: 10px;
            font-size: 14px;

            &:hover {
                background-color: $white-color;
            }
        }

        &_item_file {
            align-items: center;
            background-color: #FAFBFD;
            box-shadow: 0px 0px 5px 0px rgba($color: #000000, $alpha: 0.7);
            border-radius: 10px;
            cursor: pointer;
            display: grid;
            grid-template-columns: 50px 1fr 1fr 1fr 90px;
            gap: 10px;
            padding: 10px;
            margin-bottom: 10px;
            font-size: 14px;

            &:hover {
                background-color: $white-color;
            }
        }
    }

    &_nofiles_section {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-items: center;
        justify-content: center;
        height: 100%;
    }

    &_upload_modal_container{
        position: absolute;
        background-color: rgba($color: #000000, $alpha: 0.3);
        z-index: 10;
        width: 100vw;
        height: 100vh;

        &_input_container{
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 5px;
            height: 50vh;
            left: 50%;
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 20px;
            transform: translate(-50%, -50%);
            top: 50%;
            width: 50vw;
            z-index: 11;
        }

        &_button_container{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
        }
    }
}

