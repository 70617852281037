@import './colors.scss'; 

body {
    background-color: $backgroundLight;
    // background-color: red;
    display: flex;
    font-family: 'Open Sans';
    overflow: hidden;
    margin: 0;
  }

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100vw;
    height: 100vh;
}

