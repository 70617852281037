@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 218, 232, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 208, 222, 248;
    --background-start-rgb: 208, 222, 248;
    --background-end-rgb: 218, 232, 255; 
  }
}

body {
  color: rgba(0, 0, 0, 0.6);
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}

/* This will affect the scrollbar globally */
::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #b9cbed; /* color of the scroll thumb */
  border-radius: 80px; /* radius of the scroll thumb */
  border: 3px solid #f2f3f5; /* creates padding around scroll thumb */
}

input, select {
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus, select:focus {
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}