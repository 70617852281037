@import '../../styles//colors.scss';


.button_transparent_external{
    padding: 5px;
    border-bottom: 2px solid $BlueGreenDark;
}

.button_transparent {
    background-color: white;
    border-radius: 5px;
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    padding: 10px;
    

    &:hover {
        background-color: $LightBlue;
        color: white;
    }

    &:active {
        background-color: $TableHeaderBlue;
    }

    &__alert {
        background-color: red;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 4px;
        right: 2px;
    }
}