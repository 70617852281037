@import '../../styles//colors.scss';

.button_circle {
    background-color: $white-color;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;

    &:hover {
        background-color: $BlueGreenLight;
        color: white;
    }

    &:active {
        background-color: $cardHoverBackground;
    }

    &__alert {
        background-color: red;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 4px;
        right: 2px;
    }
}