@import '../../../styles/colors.scss';

.dashboard_container {
    display: flex;
    flex-direction: column;
    // gap: 20px;
    width: 100%;
    height: calc(100vh - 100px);
    overflow: auto;

    &_filter_container {
        border: 2px solid rgba($color: $TableHeaderBlue, $alpha: 0.5);
        border-radius: 10px;
        padding: 20px;
        margin: 20px 30px 10px 30px;
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
    }

    &_label {
        color: $DarkBlue;
        margin-right: 15px;
        margin-left: 15px;
    }
}

.download_report_modal_container {
    background-color: rgba($color: #000000, $alpha: 0.2);
    display: flex;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    &_form_container {
        background-color: white;
        border-radius: 20px;
        height: 50vh;
        left: 50%;
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 20px;
        transform: translate(-50%, -50%);
        top: 50%;
        width: 40vw;
        z-index: 11;

        &_close_section {
            display: flex;
            justify-content: flex-end;
        }

        &_info_section {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 10px;
        }

        &_title {
            color: $TableHeaderBlue;
            font-size: 18px;
            font-weight: bold;
        }

        &_subtitle {
            font-size: 14px;
            padding: 10px;
        }


    }

    &_options_container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: scroll;

        /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        /* Hide scrollbar for other browsers */
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        &_option {
            align-items: center;
            height: 35px;
            border: 2px solid $cardHoverBackground;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            padding: 10px;
            overflow: hidden;

            &:hover {
                border-color: $TableHeaderBlue;
            }

            &:active {
                background-color: $TableHeaderBlue;
                color: white;
            }
        }
    }



}

.tabs_container {
    box-shadow: 0px 0px 10px 0px rgba($color: black, $alpha: 0.2);
    border-radius: 7px;
    display: flex;
    gap: 30px;
    background-color: white;
    margin: 10px 35px;
    padding: 20px 10px;
    padding-bottom: 0px;
    height: 30px;
    justify-content: baseline;

    &_tab {
        align-items: flex-end;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        display: flex;
        min-width: 90px;
        text-align: center;
        font-size: large;

        &:hover {
            border-bottom: 3px solid $cardHoverBackground;
            font-weight: bold;
        }

        &:active {
            color: #129ED3;
        }


        &.active {
            border-bottom: 2px solid #ff8a00;
            font-weight: bold;
            color: #129ED3;
        }
    }
}

.tabs_state{
    border: 2px solid rgba($color: $LightBlue, $alpha: 0.5);
    border-radius: 7px;
    display: flex;
    gap: 30px;
    background-color: white;
    margin: 10px 35px;
    padding: 0px 10px;
    height: 50px;

    .text_label{
        display: flex;
        color: #129ED3;
        align-items: center;
        height: 50px;
        overflow: hidden;
    }

    .tab{
        align-items: center;
        color: rgba($color: #b2adad, $alpha: 1.0);
        font-size: small;
        display: flex;
        flex-direction: row;
        gap: 5px;
        padding: 0px 20px;
        cursor: pointer;

        &:hover{
            background-color: rgba($color: #75b3f5, $alpha: 0.2);
        }

        &.active{
            border-bottom: 2px solid #2553d3;
            font-weight: bold;
            color: black;
        }

        .numberItems{
            border: 1px solid $LightBlue;
            display: flex;
            flex-direction: column;
            text-align: center;
            border-radius: 15px;
            min-width: 40px;
            padding: 2px;

            &.active{
                background-color: $LightBlue;
                color: white;
            }
        }
    }
}

.table_analyst {
    margin: 20px 40px;
    font-size: small;
    // height: calc(100vh - 200px);
    // overflow: auto;
    
    .header{
        background-color: $TableHeaderBlue;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        height: 20px;
        text-align: center;
    }

    .body_row{
        background-color: white;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
        border-radius: 5px;
        margin: 15px 0px;
        padding: 5px 0px;
        text-align: center;
    }

    .body_location{
        justify-items: center;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        border-radius: 5px;
        text-align: center;
        margin: 10px;
    }

    .body_detail_container{
        border-top: 1px solid rgba(106, 213, 243, 0.6);
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        margin: 5px 30px;
        padding-top: 10px;
    }

    .sub_table_container{
        margin: 10px 20px;
        border: 3px solid $TableSubHeaderBlue;
    }

    .sub_table_header{
        background-color: $TableSubHeaderBlue;
        border-radius: 5px;
        font-weight: bold;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        padding: 10px;
    }

    .sub_table_row{          
        display: grid;
        align-items: center;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
        padding: 10px;
        border-bottom: 1px solid rgba(106, 213, 243, 0.3);
    }
}