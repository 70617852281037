@import '../../styles/colors.scss';

.history_task_container{
    display: flex;
    flex-direction: column;
    width: 100%;

    &_header {
        align-items: center;
        display: flex;
        font-size: 25px;
        color: $TableHeaderBlue;
        font-weight: bold;
        gap: 10px;
        padding: 15px 20px;
        text-align: center;
    }

    &_table_container{
        padding: 20px 40px;
        height: calc(100vh - 200px);
        overflow: auto;
        font-size: 14px;

        &_header{
            background-color: $TableHeaderBlue;
            border-radius: 5px;
            color: white;
            font-weight: bold;
            display: grid;
            grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.3fr 0.5fr;
            padding: 15px;
        }

        &_row{
            background-color: white;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
            border-radius: 10px;
        }

        &_row_info_task{          
            display: grid;
            align-items: center;
            grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.3fr 0.5fr;;
            padding: 15px;
            margin: 10px 0px;
        }

        &_row_summary_container{
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid rgba(106, 213, 243, 0.6);
            margin: 10px 30px;
            padding: 10px;
        }

        &_file_summary{
            display: flex;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
        } 
        
        &_empty_space{
            height: 10px;
            width: 20px;
        }
    }

    &_sub_table_container{
        margin: 10px 20px;
        border: 3px solid $TableSubHeaderBlue;

        &_header{
            background-color: $TableSubHeaderBlue;
            border-radius: 5px;
            font-weight: bold;
            display: grid;
            grid-template-columns: 1fr 0.5fr 1fr;
            padding: 10px;
        }

        &_row{          
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 0.5fr 1fr;
            gap: 10px;
            padding: 10px;
            border-bottom: 1px solid rgba(106, 213, 243, 0.3);
        }
    }

    &_filter_container{
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        gap: 10px;
        padding: 10px 20px;
    }
}