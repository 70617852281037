@import '../../styles/colors.scss';

.wrap-main {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.wrap-main__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 10px;
    background-color: white;
    border-right: 1px solid $LightBlue;

    &__container_header{
        align-items: center ;
        border-bottom: 1px solid $LightBlue;
        display: flex;
        gap: 20px;
        font-weight: bold;
        flex-direction: column;
        width: 250px;
        position: relative;
    }

    &__close_icon{
        background-color: white;
        border-bottom: 1px solid $LightBlue;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        cursor: pointer;
        height: 25px;
        width: 25px;   
        right: -20px; 

        &:hover{
            box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
        }
    }

    &__container_buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 250px;
    }

}

.wrap-main__content {
    display: flex;
    flex: 1;
}

.wrap-main__content_body{
    display: flex;
    width: 100%;
    height: 100%;
}

.wrap-main_login {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    &_logo {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;
    }

    &_form {
        background-color: #fff;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        width: 420px;

        &_title{
            color: black;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
        }
    }

    &__input{
        background-color: #FAFAFA;
        border-radius: 5px;
        border: none;
        outline: none;
        padding: 10px;
        width: 100%;
    }
}  

.wrap-main__loading{
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 10;
    
    &_gif{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.7;
    }

}