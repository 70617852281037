@import '../../../styles/colors.scss';


.icon_image {
    width: 20px;
    height: auto; /* Maintain aspect ratio */
}

.upload_container{
    display: flex;
    flex-direction: column;
    width: 100%;

    &_title_section{
        margin-bottom: 10px;
        padding: 10px 30px;
        border-bottom: 2px solid $LightBlue;

        &__title{
            color: black;
            font-size: 18px;
            font-weight: bold;
        }

        &__detail{
            color: grey;
            font-size: 12px;
            font-weight: bold;
        }
    }

    &_cards_section{
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding: 20px;
    }
}

.card_container{
    align-items: center;
    background-color: rgb(255, 255, 255);
    border: 1px solid $LightBlue;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    width: 300px;
    cursor: pointer;

    &__title{
        color: $DarkBlue;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    &__description{
        font-size: 14px;
        font-weight: bold;
    }

    &__alertSection{
        border-top: 1px solid $LightBlue;
        display: flex;
        gap: 10px;
        padding: 10px 0px;
        font-size: 10px;

    }

    &:hover{
        border: 1px solid $DarkBlue;
        box-shadow: 0px 0px 10px 0px $LightBlue;
    }

    &:active{
        background-color: $cardHoverBackground;
        color: white;
    }
}