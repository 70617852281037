.toast_notification_container {
    gap: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0px;
    margin: 10px;
    right: 0px;
    width: 250px;
    max-height: 100vh; /* Ensure it doesn't overflow the viewport height */
    overflow-y: scroll;
    z-index: 1000;
  
    /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  
    /* Hide scrollbar for other browsers */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }


.toast{
    background-color: white;
    border: 1px solid rgba($color: #000000, $alpha: 0.3);
    border-radius: 5px;
    padding: 10px;
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 15px; 
    position: relative;
}

.toast_success{
    @extend .toast;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.toast_error{
    @extend .toast;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.toast_info{
    @extend .toast;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.toast_close_button{
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 20px;
    color: rgba($color: #000000, $alpha: 0.3);
}