$white-color: #F6FFFF;
$BlueGreenDark: #55CCD3;
$TableHeaderBlue: #316EBC;
$TableSubHeaderBlue: #ECF6FF;
$BlueGreenLight: #2EE9E9;
$LightBlue: #129ED3;
$DarkBlue: #03365E;
$SearchBar: #F4F0F0;
$cardHoverBackground: #56bff4;
$backgroundLight: #F6FFFF;
$blueButton: #ECF6FF;