@import '../../styles/colors.scss';

.header {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid $LightBlue;
    display: grid;
    grid-template-columns: 150px 2fr 150px 200px;
    padding: 10px;
    height: 80px;

    &__logo {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &__icons_section {
        align-items: center;
        // background-color: blue;
        display: flex;
        justify-content: space-around;
        gap: 10px;
    }

    &__activeProject_button {
        background-color: $blueButton;
        border-radius: 20px;
        padding: 10px;
        font-size: small;
        font-weight: bold;
        text-align: center;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
            // background-color: $LightBlue;
        }
    }

    &__userCard {
        border-radius: 10px;
        display: flex;
        gap: 5px;
        align-items: center;
        padding: 10px;

        &_profile_image {
            align-items: center;
            color: white;
            display: flex;
            background-color: $TableHeaderBlue;
            border-radius: 50%;
            height: 30px;
            justify-content: center;
            width: 30px;
            text-transform: uppercase;
        }

        &__detail {
            font-size: 12px;
        }

        &:hover {
            background-color: $LightBlue;
            cursor: pointer;
            color: white !important;
        }

        &:active {
            background-color: $TableHeaderBlue;
        }

        &__name {
            font-size: 14px;
            font-weight: bold;
        }

    }

    &__search {
        align-items: center;
        background-color: $SearchBar;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 25px 1fr;
        gap: 10px;
        padding: 5px;
        margin: 5px 15px;

        &__input {
            background-color: transparent;
            border: none;
            outline: none;
            padding: 5px;
            width: 100%;
        }

        &__input_password {
            background-color: transparent;
            border: none;
            outline: none;
            padding: 5px;
            width: 100%;
        }
    }

    &__background_menu {
        background-color: rgba(140, 153, 238, 0.3);
        height: 100vh;
        width: 100vw;
        position: absolute;
        z-index: 8;
    }

    &__option_separator {
        border-bottom: 1px solid $LightBlue;
        margin: 10px 0;
    }

    &__menu {
        background-color: white;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
        height: 100vh;
        position: absolute;
        padding: 20px;
        right: -0px;
        top: 0;
        width: 400px;
        z-index: 9;

        display: flex;
        flex-direction: column;

        &__top_item {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 10px;
            font-weight: bold;
            font-size: 20px;

            &__close_icon {
                cursor: pointer;
                padding: 10px;
                border-radius: 20px;

                &:active {
                    background-color: $cardHoverBackground;
                }
            }
        }

        &__user_card {
            align-items: center;
            display: flex;
            gap: 10px;
            padding: 20px;
            border-bottom: 1px solid $LightBlue;
            margin-bottom: 20px;
        }

        &__options_title {
            padding: 10px;
            font-weight: bold;
        }

        &__options_list {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;
            overflow: scroll;

            /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }

            /* Hide scrollbar for other browsers */
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }
}

.header_mobile {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid $LightBlue;
    display: grid;
    grid-template-columns: 50px 2fr 150px;
    padding: 10px;
    height: 80px;

    &__logo {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &__icons_section {
        align-items: center;
        // background-color: blue;
        display: flex;
        justify-content: space-around;
        gap: 10px;
    }

    &__userCard {
        border-radius: 10px;
        display: flex;
        gap: 5px;
        align-items: center;
        padding: 10px;

        &__detail {
            font-size: 12px;
        }

        &:hover {
            background-color: $LightBlue;
            cursor: pointer;
            color: white !important;
        }

        &:active {
            background-color: $TableHeaderBlue;
        }

        &__name {
            font-size: 12px;
            font-weight: bold;
        }

    }

    &__search {
        align-items: center;
        background-color: $SearchBar;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 25px 1fr;
        gap: 10px;
        padding: 5px;

        &__input {
            background-color: transparent;
            border: none;
            outline: none;
            padding: 5px;
            width: 100%;
        }
    }
}